<template>
  <div>
    <v-row :justify="centered ? 'center' : undefined">
      <v-col cols="12">
        <p>
          <ValidationProvider v-slot="{errors, classes}"
                              :name="label"
                              :rules="rules">
            <sb-input :label="label"
                      :error="classes.invalid">
              <v-autocomplete :ref="refName"
                              v-model="selectedValue"
                              :loading="$wait.is('geAuthorityGroupsLoading')"
                              :items="tempAuthorityGroupResults"
                              :filter="customSearchAuthorityFilter"
                              :readonly="readonly"
                              :clearable="!readonly"
                              :error-messages="errors"
                              :error="classes.invalid"
                              :disabled="disabled"
                              item-text="id"
                              return-object
                              cache-items
                              hide-details
                              hide-selected
                              dense
                              outlined
                              @input="selectedChange">
                <template #no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for Authority group by
                      <strong>Authority Limit</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template #selection="{item}">
                  <v-list-item-title>
                    <v-row justify="space-between"
                           class="mx-0">
                      <div>{{ item.authorityLimitDescription }}</div>
                      <div>
                        {{ item.authorityLimit }}
                      </div>
                    </v-row>
                  </v-list-item-title>
                </template>

                <template #item="{item}">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.authorityLimit" />
                    <v-list-item-subtitle v-text="item.authorityLimitDescription" />
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <span class="field-details"
                    :class="{'error--text': errors.length > 0}">{{ errors[0] }}</span>
            </sb-input>
          </validationprovider>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ValidationProvider } from 'vee-validate';
import inputBase from '@/mixins/inputBase';
import { mapWaitingActions } from 'vue-wait';

export default {
  components: {
    ValidationProvider,
  },
  mixins: [inputBase],
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: undefined,
    },
    label: {
      type: String,
      default: 'Authority   Group    ',
    },
    authorityGroupDefaultValue: {
      type: String,
      default: undefined,
    },
    clientId: {
      type: String,
      default: undefined,
    },
    refName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchAuthorityGroupString: null,
      selectedAuthorityGroupResult: null,
      authorityGroupResults: [],
      tempAuthorityGroupResults: [],
    };
  },

  computed: {
    selectedValue: {
      get() {
        if (this.authorityGroupDefaultValue) {
          return this.tempAuthorityGroupResults.find((data) => data.authorityLimit === this.authorityGroupDefaultValue);
        }
        return this.selectedAuthorityGroupResult;
      },
      set(newValue) {
        this.selectedAuthorityGroupResult = newValue;
      },
    },
  },

  watch: {
    clientId: {
      handler(value) {
        this.searchAuthorityGroupString = value;
      },
      immediate: true,
    },
    searchAuthorityGroupString: {
      async handler(value) {
        if (value && value !== this.selectedAuthorityGroupResult) {
          await this.getAuthoirtyGroupsByClientId(value);
        }
        if (value === undefined && this.authorityGroupDefaultValue === undefined) {
          this.$refs[this.refName].cachedItems = [];
          this.selectedValue = null;
        }
      },
      immediate: true,
    },
    authorityGroupDefaultValue: {
      async handler(value) {
        if (value) {
          this.searchAuthorityGroupString = this.clientId;
        }
      },
      immediate: true,
    },
  },

  methods: {
    // folder name and map action
    ...mapActions('authorityGroups', ['getAuthorityGroups']),
    ...mapWaitingActions('authorityGroups', {
      getAuthorityGroups: 'getAuthorityGroupsLoading',
    }),
    customSearchAuthorityFilter(item, queryText) {
      const authorityLimit = item.authorityLimit ? item.authorityLimit.toLowerCase() : '';
      const authorityLimitDescription = item.authorityLimitDescription ? item.name.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return authorityLimit.includes(searchText) || authorityLimitDescription.includes(searchText);
    },

    async selectedChange() {
      this.$emit('authority-group', this.selectedAuthorityGroupResult);
    },

    async getAuthoirtyGroupsByClientId(value) {
      this.authorityGroupResults = await this.getAuthorityGroups(value);
      this.tempAuthorityGroupResults = this.authorityGroupResults;
    },
  },
};
</script>
