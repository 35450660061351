<template>
  <div>
    <v-row :justify="centered ? 'center' : undefined">
      <v-col cols="12">
        <sb-input :label="label"
                  :value="currentValue">
          <v-autocomplete v-model="selectedItemResult"
                          :loading="searchJobCardItemsLoading"
                          :items="tempResults"
                          :search-input.sync="searchItemString"
                          :filter="customSearchItemFilter"
                          item-text="merchantCode"
                          item-value="merchantCode"
                          value="id"
                          return-object
                          cache-items
                          hide-details
                          hide-selected
                          dense
                          clearable
                          outlined
                          @click:clear="clearItemResult"
                          @input="updateItemResult">
            <template #no-data>
              <v-list-item>
                <v-list-item-title>
                  Search for {{ itemType | startCase }} by
                  <strong>Merchant code / Merchant Description</strong>
                </v-list-item-title>
              </v-list-item>
            </template>

            <template #item="{item}">
              <v-list-item-content v-if="item">
                <v-list-item-title>
                  <v-row justify="space-between"
                         class="mx-0">
                    <div>{{ item.id }}</div>
                    <div>
                      {{ item.transValLimit | currency }}
                    </div>
                  </v-row>
                </v-list-item-title>
                <v-list-item-subtitle v-text="item.merchantCode" />
                <v-list-item-subtitle v-text="item.merchantDesc" />
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </sb-input>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: undefined,
    },
    merchantId: {
      type: String,
      default: '10049223',
    },
    itemType: {
      type: String,
      default: 'OTHER',
    },
    reference: {
      type: Object,
      default: () => {},
    },
    clientId: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      currentValue: null,
      searchItemString: null,
      selectedItemResult: null,
      jobCardItemResults: [],
      tempJobCardItemResults: [],
      tempResults: [],
      searchJobCardItemsLoading: false,
    };
  },

  watch: {
    async searchItemString(value) {
      const payload = {
        clientId: this.clientId,
        searchMerch: value,
      };

      this.searchJobCardItemsLoading = true;
      this.jobCardItemResults = await this.getMerchantsTypeCode(payload);
      this.tempResults = this.jobCardItemResults.merchantList;
      this.searchJobCardItemsLoading = false;
    },

    async currentValue(value) {
      const payload = {
        clientId: this.clientId,
        searchString: value,
      };
      this.searchJobCardItemsLoading = true;
      this.jobCardItemResults = await this.getMerchantsTypeCode(payload);
      this.tempResults = this.jobCardItemResults.merchantList;
      this.selectedItemResult = this.jobCardItemResults.merchantList.find((item) => item.merchantCode === this.currentValue);
      this.searchJobCardItemsLoading = false;
    },
  },

  mounted() {
    if (this.$attrs['current-value']) {
      this.currentValue = this.$attrs['current-value'];
    }
  },

  methods: {
    ...mapActions('merchantsTypeCode', ['getMerchantsTypeCode']),

    customSearchItemFilter(item, queryText) {
      if (item) {
        const merchantCode = item.productCode ? item.merchantCode.toLowerCase() : '';
        const merchantDesc = item.merchantDesc ? item.merchantDesc.toLowerCase() : '';
        const searchText = queryText.toLowerCase();
        return merchantCode.includes(searchText) || merchantDesc.includes(searchText);
      }

      return false;
    },

    async updateItemResult(item) {
      const { reference } = this;
      reference.selectedItem = item;
      this.$emit('item-update', reference);
    },

    async clearItemResult() {
      const { reference } = this;
      this.$emit('item-clear', reference);
    },
  },
};
</script>
