<template>
  <div>
    <v-row :justify="centered ? 'center' : undefined">
      <v-col cols="12">
        <p>
          <ValidationProvider v-slot="{errors, classes}"
                              :name="label"
                              :rules="rules">
            <sb-input :label="label"
                      :error="classes.invalid">
              <v-autocomplete :ref="refName"
                              v-model="selectedValue"
                              :loading="$wait.is('geAdminFeesLoading')"
                              :items="tempAdminFeeResults"
                              :filter="customSearchAdminFilter"
                              :readonly="readonly"
                              :clearable="!readonly"
                              :error-messages="errors"
                              :error="classes.invalid"
                              :disabled="disabled"
                              item-text="id"
                              return-object
                              cache-items
                              hide-details
                              hide-selected
                              dense
                              outlined
                              @input="selectedChange">
                <template #no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for Admin fee by
                      <strong>Admin Fee</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template #selection="{item}">
                  <v-list-item-title>
                    <v-row justify="space-between"
                           class="mx-0">
                      <div>{{ item.adminFeeDesc }}</div>
                      <div>
                        {{ item.adminfeeAmount | currency | '0.0' }}
                      </div>
                    </v-row>
                  </v-list-item-title>
                </template>

                <template #item="{item}">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.adminfeeAmount" />
                    <v-list-item-subtitle v-text="item.adminFeeDesc" />
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <span class="field-details"
                    :class="{'error--text': errors.length > 0}">{{ errors[0] }}</span>
            </sb-input>
          </validationprovider>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ValidationProvider } from 'vee-validate';
import inputBase from '@/mixins/inputBase';
import { mapWaitingActions } from 'vue-wait';

export default {
  components: {
    ValidationProvider,
  },
  mixins: [inputBase],
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: undefined,
    },
    label: {
      type: String,
      default: 'Admin fee',
    },
    adminFeeDefaultValue: {
      type: String,
      default: undefined,
    },
    clientId: {
      type: String,
      default: undefined,
    },
    refName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchAdminFeeString: null,
      selectedAdminFeeResult: null,
      adminFeeResults: [],
      tempAdminFeeResults: [],
    };
  },

  computed: {
    selectedValue: {
      get() {
        if (this.adminFeeDefaultValue) {
          return this.tempAdminFeeResults.find((data) => data.adminfeeAmount === this.adminFeeDefaultValue);
        }
        return this.selectedAdminFeeResult;
      },
      set(newValue) {
        this.selectedAdminFeeResult = newValue;
      },
    },
  },

  watch: {
    clientId: {
      handler(value) {
        this.searchAdminFeeString = value;
      },
      immediate: true,
    },
    searchAdminFeeString: {
      async handler(value) {
        if (value && value !== this.selectedAdminFeeResult) {
          await this.getAdminFeesByClientId(value);
        }
        if (value === undefined && this.adminFeeDefaultValue === undefined) {
          this.$refs[this.refName].cachedItems = [];
          this.selectedValue = null;
        }
      },
      immediate: true,
    },
    adminFeeDefaultValue: {
      async handler(value) {
        if (value) {
          this.searchAdminFeeString = this.clientId;
        }
      },
      immediate: true,
    },
  },

  methods: {
    // folder name and map action
    ...mapActions('adminFees', ['getAdminFees']),
    ...mapWaitingActions('adminFees', {
      getAdminFees: 'getAdminFeesLoading',
    }),
    customSearchAdminFilter(item, queryText) {
      const adminFeeAmount = item.adminFeeAmount ? item.adminFeeAmount.toLowerCase() : '';
      const adminFeeDesc = item.adminFeeDesc ? item.name.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return adminFeeAmount.includes(searchText) || adminFeeDesc.includes(searchText);
    },

    async selectedChange() {
      this.$emit('admin-fee', this.selectedAdminFeeResult);
    },

    async getAdminFeesByClientId(value) {
      this.adminFeeResults = await this.getAdminFees(value);
      if (this.adminFeeResults.constructor.name === 'Array') {
        this.tempAdminFeeResults = this.adminFeeResults;
      } else {
        this.tempAdminFeeResults = [];
      }
    },
  },
};
</script>
