<template>
  <div>
    <v-row :justify="centered ? 'center' : undefined">
      <v-col cols="12">
        <p>
          <ValidationProvider v-slot="{errors, classes}"
                              :name="label"
                              :rules="rules">
            <sb-input :label="label"
                      :error="classes.invalid">
              <v-autocomplete :ref="refName"
                              v-model="selectedValue"
                              :loading="$wait.is('getCardTypesLoading')"
                              :items="tempcardTypeResults"
                              :filter="customCardTypeFilter"
                              :readonly="readonly"
                              :clearable="!readonly"
                              :error-messages="errors"
                              :error="classes.invalid"
                              :disabled="disabled"
                              item-text="id"
                              return-object
                              cache-items
                              hide-details
                              hide-selected
                              dense
                              outlined
                              @input="selectedChange">
                <template #no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for Card type by
                      <strong> Code </strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template #selection="{item}">
                  <span>{{ item.description }}</span>
                </template>

                <template #item="{item}">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.code" />
                    <v-list-item-subtitle v-text="item.description" />
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <span class="field-details"
                    :class="{'error--text': errors.length > 0}">{{ errors[0] }}</span>
            </sb-input>
          </validationprovider>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ValidationProvider } from 'vee-validate';
import inputBase from '@/mixins/inputBase';
import { mapWaitingActions } from 'vue-wait';

export default {
  components: {
    ValidationProvider,
  },
  mixins: [inputBase],
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: undefined,
    },
    label: {
      type: String,
      default: 'PRIMARY CARD TYPE',
    },
    cardTypeDefaultValue: {
      type: String,
      default: undefined,
    },
    clientId: {
      type: String,
      default: undefined,
    },
    refName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchCardTypeString: null,
      selectedCardTypeResult: null,
      cardTypeResults: [],
      tempCardTypeResults: [],
    };
  },

  computed: {
    selectedValue: {
      get() {
        if (this.cardTypeDefaultValue) {
          return this.tempcardTypeResults.find((data) => data.code === this.cardTypeDefaultValue);
        }
        return this.selectedCardTypeResult;
      },
      set(newValue) {
        this.selectedCardTypeResult = newValue;
      },
    },
  },

  watch: {
    clientId: {
      handler(value) {
        this.searchCardTypeString = value;
      },
      immediate: true,
    },
    searchCardTypeString: {
      async handler(value) {
        if (value && value !== this.selectedCardTypeResult) {
          await this.getcardTypesByClientId(value);
        }
        if (value === undefined && this.cardTypeDefaultValue === undefined) {
          this.$refs[this.refName].cachedItems = [];
          this.selectedValue = null;
        }
      },
      immediate: true,
    },
    cardTypeDefaultValue: {
      async handler(value) {
        if (value) {
          this.searchCardTypeString = this.clientId;
        }
      },
      immediate: true,
    },
  },

  methods: {
    // folder name and map action
    ...mapActions('cardTypes', ['getCardTypes']),
    ...mapWaitingActions('cardTypes', {
      getCardTypes: 'getCardTypesLoading',
    }),
    customSearchBillingFilter(item, queryText) {
      const code = item.code ? item.code.toLowerCase() : '';
      const description = item.description ? item.description.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return code.includes(searchText) || description.includes(searchText);
    },

    async selectedChange() {
      this.$emit('card-type', this.selectedCardTypeResult);
    },

    async getcardTypesByClientId(value) {
      this.cardTypeResults = await this.getCardTypes(value);
      this.tempcardTypeResults = this.cardTypeResults.personalCardList;
    },
  },
};
</script>
